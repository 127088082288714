<template>
  <div class="demo-wrap">
    <div class="login-wrapper flex-wrapper align-items-center">
      <div class="login-form">
        <div v-if="error" class="alert alert-danger" role="alert">
          {{ error }}
        </div>
        <div class="text-center mb-4">
          <img class="logo" src="/img/logo-jma.png" width="250" />
        </div>
        <div class="text-center mb-4">
          <div v-if="errors.length > 0" class="text-danger">
            <b>Please correct the following error(s):</b>
            <ul>
              <li v-for="(error, index) in errors" :key="index">
                {{ error }}
              </li>
            </ul>
          </div>
        </div>
        <div class="form-group mb-4">
          <label class="mb-2" for="username">Username</label>
          <input
            type="text"
            class="form-control custom-form-control"
            id="username"
            v-model="username"
            name="username"
            :style="{ borderRadius: '0px' }"
            :disabled="submitted"
          />
        </div>

        <div class="form-group mb-4">
          <label class="mb-2" for="password">Password</label>
          <input
            type="password"
            class="form-control custom-form-control"
            id="password"
            v-model="password"
            name="password"
            :style="{ borderRadius: '0px' }"
            :disabled="submitted"
            @keyup.enter="doLogin"
          />
        </div>
        <div class="form-group">
          <button
            @click="doLogin"
            class="btn btn-primary form-control mt-2"
            v-if="!submitted"
          >
            Login
          </button>
          <button class="btn btn-success form-control mt-2" v-else>
            <div class="spinner-grow spinner-grow-sm" role="status"></div>
            Loading
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

let errors: string[] = [];
export default defineComponent({
  name: "login",
  data() {
    return {
      username: "",
      password: "",
      submitted: false,
      errors: errors,
    };
  },

  methods: {
    doLogin() {
      this.submitted = true;
      this.errors = [];
      if (this.username === "") {
        this.errors.push("Username required.");
      }
      if (this.password === "") {
        this.errors.push("Password required.");
      }
      if (this.errors.length > 0) {
        console.log(this.errors);
        this.submitted = false;
        return;
      }
      let data = {
        username: this.username,
        password: this.password,
      };
      localStorage.username = data.username;
      this.$router.push("/dashboard");
      this.submitted = false;
    },
  },
});
</script>

<style scoped>
.flex-wrapper {
  display: flex;
  flex-flow: row;
  position: relative;
  padding-left: 40vw;
}
.login-wrapper {
  height: 100%;
}
.login-form {
  flex: 0 0 450px;
  border: 1px solid grey;
  margin: 0px auto;
  padding: 20px 30px;
  border-radius: 6px;
  background-color: #fff;
  position: "relative";
}
.custom-form-control {
  border-radius: 0px;
  background-color: white;
}
.custom-form-control:focus {
  border-color: rgba(204, 229, 255, 0.8);
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.075) inset,
    0 0 8px rgba(204, 229, 255, 0.6);
  outline: 0 none;
  background-color: white;
}
img.logo {
  height: 20%;
}

.demo-wrap {
  align-content: center;
  height: 100%;
  display: flex;
  flex-flow: row;
}

.demo-wrap:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-image: url("../assets/jmasyariahtentang2stamp.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
