<template>
  <button
    class="btn dropdown-toggle"
    type="button"
    :id="`dropdown_${name}`"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    {{ title }}
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownPeople">
    <li v-for="(child, key) in children" :key="key">
      <router-link class="dropdown-item" :to="child.url ?? `#`">{{
        child.title
      }}</router-link>
    </li>
  </ul>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "Dropdown",
  props: {
    name: String,
    title: String,
    children: Array,
  },
});
</script>
