
import { defineComponent } from "vue";

let errors: string[] = [];
export default defineComponent({
  name: "login",
  data() {
    return {
      username: "",
      password: "",
      submitted: false,
      errors: errors,
    };
  },

  methods: {
    doLogin() {
      this.submitted = true;
      this.errors = [];
      if (this.username === "") {
        this.errors.push("Username required.");
      }
      if (this.password === "") {
        this.errors.push("Password required.");
      }
      if (this.errors.length > 0) {
        console.log(this.errors);
        this.submitted = false;
        return;
      }
      let data = {
        username: this.username,
        password: this.password,
      };
      localStorage.username = data.username;
      this.$router.push("/dashboard");
      this.submitted = false;
    },
  },
});
