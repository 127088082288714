
import { defineComponent } from "vue";
import Header from "../components/Header.vue";

export default defineComponent({
  name: "AdminLayout",
  components: {
    Header,
  },
  data() {
    return {
      username: "",
    };
  },
  mounted() {
    if (localStorage.username) {
      this.username = localStorage.username;
    } else {
      this.$router.push("/login");
    }
  },
  methods: {
    doLogout() {
      localStorage.clear();
      this.$router.push("/login");
    },
  },
});
