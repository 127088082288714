<template>
  <div>
    <div
      class="wrapper navbar-primary justify-content-between header"
      style="padding: 10px 10px 10px 10px"
    >
      <div>
        <img src="../assets/logo-jma-vertikal.png" height="50" />
      </div>
      <div>
        <button
          class="dropdown-toggle btn text-black"
          href="#"
          id="navbarDropdown"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          data-reference="parent"
        >
          {{ name }}
        </button>
        <div
          class="dropdown-menu dropdown-menu-end"
          aria-labelledby="navbarDropdown"
          style="z-index: 9999"
        >
          <button class="dropdown-item" @click="logout">Logout</button>
        </div>
      </div>
    </div>
    <div class="wrapper navbar-light bg-white p-2 sticky-top">
      <div class="dropdown">
        <router-link
          to="/Dashboard"
          :class="`btn ${path == '/dashboard' && 'btn-primary'}`"
        >
          <span><i class="fas fa-home"></i></span>
        </router-link>
        <router-link to="/dashboard" class="btn">
          <span>Dashboard</span>
        </router-link>
        <router-link to="/marketing-tools" class="btn">
          <span>Marketing Tools</span>
        </router-link>
        <dropdown name="report" title="Report" :children="report_menu" />
        <router-link to="#" class="btn">
          <span>Ilustrasi</span>
        </router-link>
        <router-link to="#" class="btn">
          <span>SPAJ</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Dropdown from "./Dropdown.vue";

export default defineComponent({
  name: "Header",
  components: { Dropdown },
  props: ["name", "logout", "path"],
  data: () => {
    return {
      report_menu: [
        {
          title: "Report Produksi Agency",
          url: "#",
        },
        {
          title: "Report Komisi",
          url: "#",
        },
      ],
    };
  },
});
</script>

<style scoped>
.header {
  background-color: hsl(47, 42%, 87%);
}
</style>
