
import { defineComponent } from "vue";
export default defineComponent({
  name: "Dropdown",
  props: {
    name: String,
    title: String,
    children: Array,
  },
});
