
import { defineComponent } from "vue";
import BestAgent from "../components/BestAgent.vue";
import Caraousel from "../components/Caraousel.vue";
import MonthlyChart from "../components/MonthlyChart.vue";

let errors: string[] = [];
export default defineComponent({
  components: { MonthlyChart, BestAgent, Caraousel },
  name: "dashboard",
  data() {
    return {
      username: "",
      password: "",
      submitted: false,
      errors: errors,
    };
  },

  methods: {},
});
