
import { defineComponent } from "vue";
import Dropdown from "./Dropdown.vue";

export default defineComponent({
  name: "Header",
  components: { Dropdown },
  props: ["name", "logout", "path"],
  data: () => {
    return {
      report_menu: [
        {
          title: "Report Produksi Agency",
          url: "#",
        },
        {
          title: "Report Komisi",
          url: "#",
        },
      ],
    };
  },
});
