
import { defineComponent } from "vue";
import { Doughnut } from "vue3-chart-v2";

export default defineComponent({
  name: "BestAgent",
  extends: Doughnut,
  mounted() {
    // Overwriting base render method with actual data.
    this.renderChart(
      {
        labels: ["Aji", "Joko", "Mira"],
        datasets: [
          {
            label: "Best Agent",
            backgroundColor: ["green", "yellow", "red"],
            data: [60, 20, 10],
          },
        ],
      },
      null
    );
  },
});
