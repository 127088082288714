<template>
  <div>
    <caraousel />
    <br />
    <div class="container">
      <div class="row align-content-center">
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Production</h5>
              <monthly-chart />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Best Agent</h5>
              <best-agent />
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">
                <a href="#" class="btn">
                  <i class="fas fa-user-plus"></i> Pembelian Polis
                </a>
              </h5>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">
                <a href="#" class="btn">
                  <i class="fas fa-file-invoice"></i> Ilustrasi
                </a>
              </h5>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">
                <a href="#" class="btn">
                  <i class="fas fa-user-friends"></i> Calon Agen
                </a>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BestAgent from "../components/BestAgent.vue";
import Caraousel from "../components/Caraousel.vue";
import MonthlyChart from "../components/MonthlyChart.vue";

let errors: string[] = [];
export default defineComponent({
  components: { MonthlyChart, BestAgent, Caraousel },
  name: "dashboard",
  data() {
    return {
      username: "",
      password: "",
      submitted: false,
      errors: errors,
    };
  },

  methods: {},
});
</script>
