<template>
  <div>
    <caraousel />
    <br />
    <div class="row align-content-center"></div>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Download Dokumen Marketing Tools</h5>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <a
              href="https://www.jmasyariah.com/wp-content/uploads/2020/02/BROSUR-ilma-3.pdf"
              target="_blank"
              >Brosur JMA ILMA</a
            >
          </li>
          <li class="list-group-item"><a href="#">Brosur JMA MUMTAZA</a></li>
          <li class="list-group-item"><a href="#">Brosur JMA ASYIFA</a></li>
          <li class="list-group-item"><a href="#">Brosur JMA SALAMA</a></li>
          <li class="list-group-item"><a href="#">Brosur JMA AGHNIA</a></li>
        </ul>
      </div>
    </div>
    <br />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Caraousel from "../components/Caraousel.vue";

export default defineComponent({
  components: { Caraousel },
  name: "marketing-tools",
  data() {
    return {};
  },

  methods: {},
});
</script>
