import { createWebHistory, createRouter } from "vue-router";
import { RouteRecordRaw } from "vue-router";
import AdminLayout from "./layouts/Admin.vue";
import PublicLayout from "./layouts/Public.vue";
import Login from "./pages/Login.vue";
import Dashboard from "./pages/Dashboard.vue";
import MarketingTools from "./pages/MarketingTools.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Login,
  },
  {
    path: "/login",
    component: PublicLayout,
    children: [
      {
        path: "",
        component: Login,
      },
    ],
  },
  {
    path: "/dashboard",
    component: AdminLayout,
    children: [
      {
        path: "",
        component: Dashboard,
      },
    ],
  },
  {
    path: "/marketing-tools",
    component: AdminLayout,
    children: [
      {
        path: "",
        component: MarketingTools,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
