<template>
  <div>
    <Header :name="username" :logout="doLogout" :path="path" />
    <router-view />
    <footer id="colophon" class="site-footer" role="contentinfo">
      <div class="site-info">
        <div class="container">
          <b>KANTOR PUSAT: </b>Graha Kospin JASA Lantai 5<br />Jl. Jend. Gatot
          Soebroto Kav. 1, Jakarta Selatan, 12870, Indonesia <br />
          <b>LAYANAN PELANGGAN: </b>Telepon: 021 – 82470083 | Email:
          cs.info@jmasyariah.com<br />
          © 2021, <b>JMA Syariah</b>. Hak cipta dilindungi <br />
          <b>PT. Asuransi Jiwa Syariah Jasa Mitra Abadi Tbk</b> terdaftar dan
          diawasi oleh Otoritas Jasa Keuangan <br />
        </div>
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Header from "../components/Header.vue";

export default defineComponent({
  name: "AdminLayout",
  components: {
    Header,
  },
  data() {
    return {
      username: "",
    };
  },
  mounted() {
    if (localStorage.username) {
      this.username = localStorage.username;
    } else {
      this.$router.push("/login");
    }
  },
  methods: {
    doLogout() {
      localStorage.clear();
      this.$router.push("/login");
    },
  },
});
</script>

<style>
footer {
  display: block;
}
.site-footer {
  background-color: #e0ae1b;
  font-size: 14px;
}
.site-footer .site-info {
  position: relative;
  text-align: center;
  padding: 35px 0;
  background: #222;
  background-color: rgb(34, 34, 34);
  font-weight: 500;
  color: #bbb;
}
.site-footer .footer-connect .follow-heading,
.site-footer .footer-social a {
  color: #ffffff;
}
.site-footer .site-info,
.site-footer .btt a {
  background-color: #3da991;
}
.site-footer .site-info {
  color: #ffffff;
}
.site-footer .btt a,
.site-footer .site-info a {
  color: #ffffff;
}
</style>
