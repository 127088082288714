
import { defineComponent } from "vue";
import Caraousel from "../components/Caraousel.vue";

export default defineComponent({
  components: { Caraousel },
  name: "marketing-tools",
  data() {
    return {};
  },

  methods: {},
});
